.wrap-tab-informasi {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
}

.wrap-card-informasi {
  width: 50%;
  padding: 6px;
  cursor: pointer;
}
.wrap-card-informasi:active {
  width: 50%;
  padding: 6px;
  cursor: pointer;
  color: unset;
}

.wrap-card-informasi .card-content {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  min-height: 200px;
}
.wrap-card-informasi .card-content:hover {
  background-color: rgb(255 255 255 / 36%);
  padding: 16px;
  border-radius: 4px;
  min-height: 200px;
}

.date-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.date-attachment p {
  font-size: 12px;
  color: #8b8b8b;
}

.attachment {
  display: flex;
  align-items: center;
}

.description-card-informasi {
  margin-bottom: 12px;
}
.description-card-informasi p:first-child {
  font-size: 14px;
  color: #8b8b8b;
}
.description-card-informasi p:last-child {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.audience-card-informasi {
  margin-bottom: 12px;
}
.audience-card-informasi p {
  font-size: 14px;
  color: #8b8b8b;
  margin-bottom: 4px;
}

.box-audience {
  display: flex;
}

.item-audience {
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: 12px;
  color: #d0d0d0;
  justify-content: center;
}

.item-audience p {
  font-size: 12px;
  margin-bottom: 0;
  margin-right: 4px;
}

.item-audience.active {
  border: 1px solid #05aa5b;
  color: #05aa5b;
  background-color: #05aa5a15;
}

.detail-informasi {
  padding: 24px;
  background-color: white;
  border-radius: 4px;
}

.deskripsi-detail-informasi {
  padding-top: 12px;
}
.deskripsi-detail-informasi p:first-child {
  color: #8b8b8b;
  margin-bottom: 4px;
}

.attachment-detail-informasi-wrap {
  padding-top: 16px;
}

.attachment-detail-informasi p {
  color: #8b8b8b;
  margin-bottom: 4px;
}

.attachment-detail-informasi {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  background-color: #e8edfb;
}

.item-atta-detail-informasi {
  width: 20%;
  padding: 12px;
}

.content-atta {
  background-color: white;
  border-radius: 6px;
}

.icon-file-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  color: red;
}

.des-file-detail {
  background-color: #fbf5e8;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 12px;
}
.des-file-detail p:first-child {
  font-size: 14px;
  margin-bottom: 0;
}

.file-size-style {
  font-size: 12px;
  margin-bottom: 24px !important;
}

.des-file-detail button {
  background-color: blue;
  outline: none;
  border: none;
  width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  color: white;
}

.table-wrap-hasil-terbaru {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  height: 80vh;
  overflow: auto;
}
.table-wrap-hasil-terbaru th {
  border-bottom: 0px;
}
.table-wrap-hasil-terbaru td {
  border-bottom: 0px;
}

.hasil-table {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #026eb8;
  padding: 6px 0;
  border-radius: 4px;
  color: #026eb8;
  cursor: pointer;
}

.hasil-table-read {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d05604;
  padding: 6px 0;
  border-radius: 4px;
  color: #d05604;
  cursor: pointer;
}
.hasil-table p {
  padding-left: 4px;
}

.head-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
}

.head-search-single {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 24px;
}

.wrap-date {
  display: flex;
  align-items: center;
}
.wrap-date input {
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 11px 16px;
  border-radius: 4px;
}

.wrap-date button {
  background-color: #026eb8;
  border: none;
  outline: none;
  padding: 11px 16px;
  margin-left: 12px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.wrap-date p {
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .head-search {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    padding-top: 24px;
    flex-direction: column;
  }
  .wrap-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .wrap-date input {
    width: 100%;
  }
  .wrap-date button {
    width: 100%;
    margin-left: 0;
    margin-top: 12px;
  }

  .search-style {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-top: 12px;
  }
  .search-style input {
    border: none;
    outline: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 0;
    width: 100%;
  }

  .item-atta-detail-informasi {
    width: 50% !important;
    padding: 12px;
  }
}

@media screen and (max-width: 480px) {
  .wrap-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .wrap-date input {
    width: 100%;
  }
  .wrap-date button {
    width: 100%;
    margin-left: 0;
  }

  .search-style input {
    border: none;
    outline: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 0;
    width: 100%;
  }
}
