.header-table-action {
  display: flex;
  align-items: center;
}

.text-action {
  color: green;
  font-weight: 400;
  background: #f6fff6;
  padding: 5px 15px;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid green;
}
.text-action-danger {
  color: red;
  font-weight: 400;
  background: #fff9f9;
  padding: 5px 15px;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid red;
}

.header-table-action p {
  margin-right: 12px;
}
.header-table-action button {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}
.header-table-action button.active {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  background-color: #4e5cf2;
  color: white;
}
.header-table-action button.active:last-child {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  background-color: #f24141;
  color: white;
}

.header-table-action button p {
  margin-right: 4px;
}

.head-toolbar-action {
  padding-top: 12px;
  padding-bottom: 12px;
}
.head-toolbar-action.active {
  background-color: #053ed01c;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  border-radius: 4px;
}

.checkTab.Mui-selected {
  background-color: #053ed01c !important;
}
.checkTab.Mui-checked {
  color: #4e5cf2 !important;
}

.action-tab-hasil-rujukan {
  display: flex;
  align-items: center;
  justify-content: left;
}
.action-tab-hasil-rujukan button {
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  cursor: pointer;
}
.action-tab-hasil-rujukan button:hover {
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  background-color: #4e5cf2;
  color: white;
  cursor: pointer;
}
.table-head-hasil-rujukan {
  background-color: #f6f8fa;
}

.btn-daftar-rujukan {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.btn-daftar-rujukan button {
  border: none;
  outline: none;
  padding: 11px 16px;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 16px;
  background-color: #026eb8;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-style-rujuk {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: flex-end;
}

.search-style-rujuk input {
  border: none;
  outline: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 100%;
}
.search-style-rujuk button {
  border: none;
  outline: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #dcdcdc;
  color: black;
}

.search-style-periksa {
  display: flex;
  align-items: center;
  /* width: 80%; */
  justify-content: center;
  margin: 0 auto;
}

.search-style-periksa input {
  border: none;
  outline: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 80%;
}
.search-style-periksa button {
  border: none;
  outline: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #dcdcdc;
  color: black;
  cursor: pointer;
}

.subtitle-card-add {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 12px;
}

.subtitle-card-add p {
  margin-right: 12px;
  min-width: 150px;
  text-align: right;
}

.wrap-pasien-rujukan {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
}

.wrap-input-form {
  display: flex;
  width: 100%;
}
.wrap-input-form-asyn {
  display: flex;
  width: 100%;
}
.wrap-input-form-textarea {
  width: 100%;
}

.wrap-input-add {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 45%;
}

.wrap-input-add-textarea {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
  align-items: flex-start;
}
.wrap-input-add-textarea textarea {
  width: 72.3%;
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-radius: 4px;
  min-height: 150px;
}
.wrap-input-add-textarea p {
  text-align: right;
  margin-right: 12px;
  min-width: 16.7%;
}

.wrap-input-add-asyn {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 45%;
}

.wrap-input-add-panggilan {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 45%;
}

.wrap-input-add-asyn > div {
  width: 100%;
}

.wrap-input-add-panggilan > div {
  width: 100%;
}

.wrap-input-add-panggilan p {
  text-align: right;
  margin-right: 12px;
  min-width: 37%;
}
.wrap-input-add-asyn p {
  text-align: right;
  margin-right: 12px;
  min-width: 37%;
}

.wrap-input-add p {
  text-align: right;
  margin-right: 12px;
  min-width: 37%;
}

.wrap-input-add-spesiment {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 45%;
}
.wrap-input-add-spesiment p {
  text-align: right;
  margin-right: 12px;
  min-width: 37%;
  width: 0;
}

.date-custome {
  /* background-color: #043ed0; */
  /* background: #4e5cf2; */
  width: 100%;
}

.wrap-input-add-spesiment input {
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 16px 22px;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
}
.wrap-input-add input {
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-radius: 4px;
  width: 80%;
}

.container-table-add {
  width: 87%;
  margin: 0 auto;
}

.table-wrap-add-pasien {
  margin-top: 0px;
  padding: 24px;
  background-color: white;
  overflow: auto;
}

.table-wrap-add-pasien th {
  border-bottom: 0px;
}
.table-wrap-add-pasien td {
  border-bottom: 0px;
}

.total-add {
  display: flex;
  justify-content: space-between;
  min-width: 30%;
  background-color: #fff8dd;
  align-items: center;
  margin-right: 24px;
  padding: 5px 16px;
  border: 1px solid #ffc700;
  border-radius: 4px;
  border-style: dashed;
}

@media screen and (max-width: 768px) {
  .btn-daftar-rujukan button {
    border: none;
    outline: none;
    padding: 11px 16px;
    border-radius: 4px;
    margin-right: 6px;
    font-size: 16px;
    background-color: #026eb8;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .search-style-rujuk {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .search-style-rujuk input {
    border: none;
    outline: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 0;
    width: 100%;
  }
  .btn-daftar-rujukan {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;
  }

  .add-btn-rujuk {
    width: 100%;
    margin-top: 12px;
  }
  .add-btn-rujuk button {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrap-input-form {
    display: flex;
    width: 100% !important;
    flex-direction: column;
  }
  .wrap-input-add {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    width: 100% !important;
    flex-direction: column;
  }

  .wrap-input-add p {
    text-align: left;
    margin-right: 12px;
    min-width: 37%;
    margin-bottom: 6px;
  }

  .wrap-input-add input {
    outline: none;
    border: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-radius: 4px;
    width: 100% !important;
  }

  .wrap-input-add-textarea {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100% !important;
    align-items: flex-start;
    flex-direction: column;
  }

  .wrap-input-add-textarea textarea {
    width: 100% !important;
    outline: none;
    border: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-radius: 4px;
    min-height: 150px;
  }

  .wrap-input-add-textarea p {
    text-align: left;
    margin-right: 12px;
    min-width: 16.7%;
    margin-bottom: 6px;
  }
}
