.change-pass {
  background-color: white;
  padding: 24px;
}

.wrap-input-all {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.wrap-input-all p {
  min-width: 220px;
  text-align: right;
  margin-right: 12px;
}

.wrap-input-all input {
  outline: none;
  border: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-radius: 4px;
  width: 25%;
}

.btn-submit {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.btn-submit button {
  outline: none;
  border: none;
  background-color: #026eb8;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .subtitle-card p {
    margin-right: 12px;
    min-width: 0;
    text-align: right;
  }

  .wrap-input-all {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    flex-direction: column;
  }

  .wrap-input-all p {
    min-width: 0;
    text-align: right;
    margin-right: 12px;
    margin-bottom: 4px;
  }
  .wrap-input-all input {
    outline: none;
    border: none;
    background-color: #f0f4f9;
    padding: 12px 22px;
    border-radius: 4px;
    width: 100%;
  }
}
