.wrap-tab-informasi {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
}

.wrap-card-informasi {
  width: 50%;
  padding: 6px;
  cursor: pointer;
}
.wrap-card-informasi:active {
  width: 50%;
  padding: 6px;
  cursor: pointer;
  color: unset;
}

.wrap-card-informasi .card-content {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  min-height: 200px;
  max-height: 265px;
}
.wrap-card-informasi .card-content:hover {
  background-color: rgb(255 255 255 / 36%);
  padding: 16px;
  border-radius: 4px;
  min-height: 200px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 1.2em;
  white-space: nowrap;

  /* display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
  /* font-size: 10px; */
}

.date-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.date-attachment p {
  font-size: 12px;
  color: #8b8b8b;
}

.attachment {
  display: flex;
  align-items: center;
}

.description-card-informasi {
  margin-bottom: 12px;
}
.description-card-informasi p:first-child {
  font-size: 14px;
  color: #8b8b8b;
}
.description-card-informasi p:last-child {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.audience-card-informasi {
  margin-bottom: 12px;
}
.audience-card-informasi p {
  font-size: 14px;
  color: #8b8b8b;
  margin-bottom: 4px;
}

.box-audience {
  display: flex;
}

.item-audience {
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: 12px;
  color: #d0d0d0;
  justify-content: center;
}

.item-audience p {
  font-size: 12px;
  margin-bottom: 0;
  margin-right: 4px;
}

.item-audience.active {
  border: 1px solid #05aa5b;
  color: #05aa5b;
  background-color: #05aa5a15;
}

.detail-informasi {
  padding: 24px;
  background-color: white;
  border-radius: 4px;
}

.deskripsi-detail-informasi {
  padding-top: 12px;
}
.deskripsi-detail-informasi p:first-child {
  color: #8b8b8b;
  margin-bottom: 4px;
}

.attachment-detail-informasi-wrap {
  padding-top: 16px;
}

.attachment-detail-informasi p {
  color: #8b8b8b;
  margin-bottom: 4px;
}

.attachment-detail-informasi {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  background-color: #e8edfb;
}

.item-atta-detail-informasi {
  width: 20%;
  padding: 12px;
}

.content-atta {
  background-color: white;
  border-radius: 6px;
}

.icon-file-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  color: red;
}

.des-file-detail {
  background-color: #fbf5e8;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 12px;
  word-wrap: break-word;
}
.des-file-detail p:first-child {
  font-size: 14px;
  margin-bottom: 3px;
}

.file-size-style {
  font-size: 12px;
  margin-bottom: 24px !important;
}

.des-file-detail button {
  background-color: blue;
  outline: none;
  border: none;
  width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.table-wrap-hasil-terbaru {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  height: 80vh;
  overflow: auto;
}
.table-wrap-hasil-terbaru th {
  border-bottom: 0px;
}
.table-wrap-hasil-terbaru td {
  border-bottom: 0px;
}

.hasil-table {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #026eb8;
  padding: 6px 0;
  border-radius: 4px;
  color: #026eb8;
}
.hasil-table p {
  padding-left: 4px;
}

.head-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.not-data {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.not-data img {
  object-fit: contain;
  height: 40%;
  width: 40%;
}

.not-data p {
  padding-top: 16px;
}

@media screen and (max-width: 768px) {
  .wrap-card-informasi {
    width: 100% !important;
  }

  .wrap-card-informasi:active {
    width: 100% !important;
  }

  .item-atta-detail-informasi {
    width: 50%;
    padding: 12px;
  }
}
