body,
* {
  /* font-family: 'Poppins', sans-serif !important;
  font-size: 16px; */
  font-family: 'Poppins', sans-serif !important;
  /* font-size: 14px; */
}

.wrap-logout {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  cursor: pointer;
}
.wrap-logout p {
  padding-left: 12px;
}

.logo-sidebar {
  height: 72px;
  padding: 4px;
  background-color: white;
  width: 180px;
  margin: 0 auto;
  border-radius: 6px;
}

.user-header-wrap p {
  font-size: 16px;
  margin-right: 12px;
}
.user-header-wrap {
  color: black;
  font-size: 32px;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.logo-biosis {
  height: 35px;
  width: 100px;
}
.logo-biosis img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.logo-sidebar img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.wrap-info-user {
  padding: 16px;
  color: black;
}

.content-wrap-user {
  background-color: white;
  border-radius: 6px;
  /* padding: 16px; */
  height: fit-content;
}
.content-wrap-user .title-info-user {
  text-align: center;
  padding: 12px 0;
}

.barcode-wrap {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.barcode-wrap img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 16px;
}

.info-user-wrap {
  padding: 16px;
  background-color: #e8edfb;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.style-pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.search-style {
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: flex-end;
}

.search-style input {
  border: none;
  outline: none;
  background-color: #f0f4f9;
  padding: 12px 22px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 100%;
}
.search-style button {
  border: none;
  outline: none;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgb(193, 193, 193);
  margin: 24px 0;
}

.subtitle-card {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 12px;
}

.subtitle-card p {
  margin-right: 12px;
  min-width: 220px;
  text-align: right;
}

.login-wrap {
  display: flex;
  background-color: white;
  width: 100%;
}

.img-log {
  height: 100vh;
  width: 100%;
  flex: 1;
}
.img-log img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.wrap-auth {
  /* width: 100%; */
  flex: 1;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-auth {
  width: 60%;
  margin: 0 auto;
}

.input-login {
  background-color: #eaf0f7;
  outline: none;
  border: none;
  height: 46px;
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.title-inp {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #444;
}

.logo-login-auth {
  height: 90px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.logo-login-auth img {
  height: 60%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .img-log {
    display: none;
  }

  .login-wrap {
    background-image: url('/images/login-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content-auth {
    width: 70%;
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 13px;
    background-color: white;
  }
}
